.Login {
    text-align: center;
  }

  .login-height {
      height: 100vh;
  }

.imageLogin {
    background-image: url("../../../public/login.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow-x:hidden;
}
.form {
    position: relative;
    z-index: 1;
    max-width: 100%;
    padding: 45px;
    text-align: center;
  }
  .all-width {
    width: 100% !important;
}
@media (min-width: 640px) {
    .form {
        margin: auto;
        max-width: 360px;
    }
}