body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.layout-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.layout-wrapper {
  min-height: 100% !important;
  position: relative;
}

.layout-main {
  padding-bottom: 60px !important;
}

.p-datepicker {
  top: 0 !important;
}

.text-column {
  word-break: keep-all;
  word-wrap: break-word;
}

input:focus {
  outline: none !important;
  border: 2px solid #007ad9 !important;
  box-shadow: 0 0 10px #719ece !important;
}

.p-dropdown:focus {
  border: 2px solid #007ad9 !important;
}

.welcome-image {
  height: 80vh;
  width: 100%;
  object-fit: cover;
  padding: 0 !important;
  margin: 0 !important;
}

.spacer10 {
  height: 10px;
  width: 100%;
}

.spacer20 {
  height: 20px;
  width: 100%;
}

.custom-card {
  background-color: #ffffff;
  padding: 1em;
  margin-bottom: 16px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.custom-card h1 {
  margin: 0.5em 0 0.5em 0;
  padding: 0.1em;
  font-size: 24px;
  color: #ffffff;
}

.custom-card #searchLabel {
  display: block;
  text-align: center;
  line-height: 150%;
  font-size: 1.5em;
}

.custom-card h1:first-child {
  margin: 0 0 0.5em 0;
}

.custom-card>.custom-card-header-info {
  background: linear-gradient(to right, #5d73a5 0%, #98b4d4 100%);
  margin: 0;
  padding: 10px 10px 0px 10px;
  border-bottom: 3px solid black;
  border-radius: 2px;
}

.custom-card>.custom-card-header-create {
  background: linear-gradient(to right, #5da56d 0%, #98d4a5 100%);
  margin: 0;
  padding: 10px 10px 0px 10px;
  border-bottom: 3px solid black;
  border-radius: 2px;
}

.custom-card>.custom-card-header-edit {
  background: linear-gradient(to right, #a59a5d 0%, #d4d098 100%);
  margin: 0;
  padding: 10px 10px 0px 10px;
  border-bottom: 3px solid black;
  border-radius: 2px;
}

.spiner {
  font-size: 6em !important;
  color: #007ad9 !important;
}

.spiner-container {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}


.reference-number {
  border-color: #3f9e05 !important;
}

.color-red {
  color: red !important;
}

.title-receipt {
  color: black !important;
}

.p-datatable .p-datatable-tbody>tr {
  width: 1%;
  white-space: nowrap;
}

.p-datatable .p-datatable-tbody>tr>td {
  overflow: hidden !important;
  white-space: initial !important;
  width: 20px !important;
}

.p-inputtext {
  width: 100% !important;
}

.p-dropdown {
  width: 100% !important;
}

.p-button {
  margin-top: 10px ;
  margin-right: 10px;
  width: auto !important;
}

.button-icon {
  width: 30px !important
}

.toolbar-button {
  margin: .25em !important;
}

.p-dialog {
  width: 70vw !important;
}

.swal2-container {
  z-index: 20000 !important;
}

/* Responsive */
@media (min-width: 1025px) {
  .dialog-waiting {
    width: 30vw !important;
    height: 20vw !important;
  }
}


@media (max-width: 1024px) {
  .dialog-waiting {
    width: 80vw !important;
    height: 80vw !important;
  }
}