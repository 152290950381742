.panel-contract > .p-panel-header {
    background-color: #d4d098 !important; 
}

.panel-client > .p-panel-header {
    background-color: #98d4a5 !important;
}

.panel-client >  .p-panel-header  > .p-panel-title {
   color: white !important;
}

.panel-client >  .p-panel-header  > a {
    color: white !important;
 }

 .panel-contract >  .p-panel-header  > .p-panel-title {
    color: white !important;
 }

 .panel-contract >  .p-panel-header  > a {
    color: white !important;
 }

 

 .panel-benef > .p-panel-header {
    background-color: #5D73A5 !important; 
}

.panel-benef >  .p-panel-header  > .p-panel-title {
    color: white !important;
 }

 .panel-benef >  .p-panel-header  > a {
    color: white !important;
 }
